import {Box} from "@mui/material";
import React, {useMemo} from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark } from "react-syntax-highlighter/dist/esm/styles/prism";

export function SsoIframeIntegration(): JSX.Element {
    const iframeCode = useMemo(() => [
        "<iframe",
        "    allow='camera; microphone'",
        "    allowFullScreen=''",
        "    referrerPolicy='no-referrer'",
        "    src='https://SUBDOMAIN.codenow.live/?token=AUTHENTICATION_TOKEN'",
        "    title='Brite iframe'",
        "    frameBorder='0'>",
        "</iframe>",
    ].join("\n"), []);

    return (
        <div>
            <p>This document describes the integration process between your platform and Brite.</p>
            <p>Brite provides limited functionality via customer-facing APIs. Brite APIs are available via public REST endpoints. Every customer receives an API key, a provider unique identifier, one or more school and curriculum unique identifiers.</p>
            <p>The API key is passed as an HTTP header named "SMART".</p>

            <p>The login process is composed by two steps:</p>
            <ul>
                <li>Backend-to-backend communication to log a user in and generate and authentication token</li>
                <li>Page with iframe tag including the authentication token</li>
            </ul>

            <p>The diagram below shows the sequence of events involved in the first step:</p>
            <img alt={"Diagram - User Token Login"} src={"/images/diagram_user_token_login.svg"} />

            <p>Below is an example of an iframe generated by customer platform:</p>
            <Box>
                <SyntaxHighlighter language="cshtml" style={a11yDark}>
                    {iframeCode}
                </SyntaxHighlighter>
            </Box>
        </div>
    );
}
