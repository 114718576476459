import React from "react";
import SwaggerUI from "swagger-ui-react";
import {SwaggerLayoutPlugin} from "../swagger/layout/SwaggerLayoutPlugin";
import "swagger-ui-react/swagger-ui.css";

export function ApiDoc(): JSX.Element {
    return (
        <SwaggerUI
            layout={"SwaggerLayout"}
            plugins={[SwaggerLayoutPlugin]}
            url={`/external-v1.yaml`}
        />
    );
}
