import React from "react";

interface IProps {
    getComponent: (s: string, b: boolean) => any;
}

export function SwaggerLayout({
    getComponent
}: IProps): React.ReactElement {
    const BaseLayout = getComponent("BaseLayout", true);

    // We can add any customizations to Swagger UI here
    return (
        <div>
            <BaseLayout />
        </div>
    );
}
