import {Box, Container, Divider, Tab, Tabs} from "@mui/material";
import React, {useState} from "react";
import {ApiDoc} from "./page/ApiDoc";
import {SsoIframeIntegration} from "./page/SsoIframeIntegration";

export function Application(): JSX.Element {
    const [selectedTab, setSelectedTab] = useState<number>(0);

    const onTabSelectionChange = (event: React.SyntheticEvent, value: any) => {
        setSelectedTab(value);
    };

    return (
        <Container>

            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={selectedTab} onChange={onTabSelectionChange}>
                    <Tab id={"0"} label="API Documentation" />
                    <Tab id={"1"} label="SSO Iframe Integration" />
                </Tabs>
            </Box>

            <Divider/>

            {selectedTab === 0 && <ApiDoc/>}
            {selectedTab === 1 && <SsoIframeIntegration/>}
        </Container>
    );
}
